/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react/prop-types */
import React from 'react';

import type { NextPage, NextPageContext } from 'next';
import { type NextFont } from 'next/dist/compiled/@next/font';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSession, signIn } from 'next-auth/react';

import { Layout, Loader } from '../components/index';
import {
  Google,
  FeaturesTopLeft,
  FeaturesBottomLeft,
  FeaturesTopRight,
  FeaturesRightGroup,
  FeaturesTopLeftMobile,
  FeaturesBottomLeftMobile,
  FeaturesRightGroupMobile,
  FeaturesTopRightMobile
} from '../components/icons';

const Login: NextPage<
{ inter: NextFont, redirect?: string },
{ redirect?: string }
> = ({ inter, redirect }) => {
  const { data: session } = useSession();
  const { push } = useRouter();
  if (session != null) {
    setTimeout(() => {
      void push('/');
    }, 5000);
    return <Loader />;
  }
  return (
    <Layout title="Log In">
      <div
        className={`relative flex h-[100vh] w-full items-center bg-[#1F0231] p-8 lg:p-12 ${inter.className}`}
      >
        <div className="z-20 m-auto gap-8 flex justify-between items-center lg:w-11/12 w-full">
          <Link
            href={'/'}
            className="absolute lg:top-16 top-8 left-8 md:top-12 lg:left-12"
          >
            <Image
              src={'/TheconnectedawardsLogo.png'}
              quality={100}
              width={160 * 4}
              height={97.53 * 4}
              alt="Link Back Home"
              className="lg:w-36 w-16 h-auto"
            />
          </Link>
          <div className="text-white w-fit lg:flex hidden flex-col gap-3">
            <h2 className="text-3xl font-medium">
              Let’s pick up where we left off yeah
            </h2>
            <h5 className="font-extralight text-lg">Sign in to continue</h5>
          </div>
          <div className="flex flex-col font-light bg-white p-10 text-center lg:basis-1/2 space-y-6 lg:py-24 py-6 text-sm w-full mx-auto items-center">
            <div className="w-fit flex flex-col gap-3 lg:hidden items-center">
              <h2 className="lg:text-3xl text-lg font-medium">
                Let’s pick up where we left off yeah
              </h2>
              <h5 className="font-extralight lg:text-lg text-xs">
                Sign in to continue
              </h5>
            </div>
            <div
              className="flex  h-auto w-full cursor-pointer items-center justify-center gap-2 border border-[#D8CDE0] bg-[#D8CDE0]/10 px-3 py-4 transition-all duration-500 ease-in hover:border-[#D8CDE0]/50 hover:bg-[#D8CDE0]"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={async () =>
                await signIn('google', {
                  callbackUrl: redirect
                })
              }
            >
              <Google className="h-auto w-4" />
              <span>Continue with google</span>
            </div>
            <div className="flex flex-row w-fit mx-auto text-[#757474]">
              New User?
              <Link
                href={`/sign-up${
                  redirect ?? false ? `?redirect=${redirect ?? ''}` : ''
                }`}
                className="text-[#3E0563] font-medium ml-1"
              >
                Create account
              </Link>
            </div>
          </div>
        </div>
        <FeaturesTopLeft className="absolute w-56 h-auto top-[25%] -left-36 z-0 lg:block hidden" />
        <FeaturesTopLeft className="absolute w-56 h-auto scale-90 bottom-[5%] left-[36%] z-0 lg:block hidden" />
        <FeaturesBottomLeft className="absolute bottom-[10%] left-16 z-0 lg:block hidden" />
        <FeaturesRightGroup className="absolute w-40 h-auto bottom-[10%] right-0 z-0 lg:block hidden" />
        <FeaturesTopRight className="absolute w-40 h-auto top-[20%] -right-24 z-0 lg:block hidden" />

        <FeaturesBottomLeftMobile className="absolute bottom-10 left-16 z-0 lg:hidden" />
        <FeaturesRightGroupMobile className="absolute bottom-0 right-0 z-0 lg:hidden" />
        <FeaturesTopLeftMobile className="absolute top-24 left-0 z-0 lg:hidden" />
        <FeaturesTopRightMobile className="absolute top-[10%] right-12 z-0 lg:hidden" />
      </div>
    </Layout>
  );
};

Login.getInitialProps = async (
  ctx: NextPageContext & { query: { redirect?: string } }
) => {
  return { redirect: ctx.query.redirect };
};

export default Login;
